import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Layout, Modal, message, Form, Input, Select, Button, Table, Space, Card, Typography, Tag } from 'antd';
import Sidebar from '../components/Sidebar';

const { Content, Sider } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

const BlogPage = () => {
    const [blogs, setBlogs] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const [newBlog, setNewBlog] = useState({
        title: '',
        content: '',
        category: '',
        tags: '',
        status: 'draft',
        author: '65f1234567890abcdef12345'
    });
    const [editingBlog, setEditingBlog] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blogs`);
            setBlogs(response.data);
        } catch (error) {
            console.error('Error fetching blogs:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewBlog(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedBlog = {
                ...newBlog,
                tags: newBlog.tags.split(',').map(tag => tag.trim())
            };

            await axios.post(`${process.env.REACT_APP_API_URL}/api/blogs`, formattedBlog);
            setNewBlog({
                title: '',
                content: '',
                category: '',
                tags: '',
                status: 'draft',
                author: '65f1234567890abcdef12345'
            });
            fetchBlogs();
            message.success('Blog created successfully');
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to create blog';
            message.error(errorMessage);
            console.error('Error creating blog:', error);
        }
    };

    const handleEdit = (blog) => {
        setEditingBlog({
            ...blog,
            tags: Array.isArray(blog.tags) ? blog.tags.join(', ') : '',
            author: blog.author || '65f1234567890abcdef12345'
        });
        setIsEditModalOpen(true);
    };

    const handleDelete = async (blog) => {
        if (window.confirm('Are you sure you want to delete this blog?')) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/blogs/${blog.slug}`);
                fetchBlogs();
            } catch (error) {
                console.error('Error deleting blog:', error);
            }
        }
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditingBlog(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleUpdate = async (values) => {
        setUpdateLoading(true);

        try {
            if (!values.title || !values.content || !values.category) {
                throw new Error('Please fill in all required fields');
            }

            const formattedBlog = {
                ...values,
                tags: typeof values.tags === 'string' 
                    ? values.tags.split(',').map(tag => tag.trim()).filter(Boolean)
                    : values.tags,
                author: editingBlog.author || '65f1234567890abcdef12345'
            };

            const { _id, createdAt, updatedAt, __v, ...blogToUpdate } = formattedBlog;

            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/blogs/${editingBlog.slug}`, blogToUpdate);
            
            if (response.status === 200) {
                message.success('Blog updated successfully');
                setIsEditModalOpen(false);
                setEditingBlog(null);
                fetchBlogs();
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || error.message || 'Failed to update blog';
            message.error(errorMessage);
            console.error('Error updating blog:', error);
        } finally {
            setUpdateLoading(false);
        }
    };

    const EmptyState = () => (
        <tr>
            <td colSpan="5">
                <div>No blogs found</div>
            </td>
        </tr>
    );

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Content Preview',
            dataIndex: 'content',
            key: 'content',
            render: (text) => text.substring(0, 100) + '...'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <span style={{ textTransform: 'capitalize' }}>{status}</span>
            )
        },
        {
            title: 'Tags',
            dataIndex: 'tags',
            key: 'tags',
            render: (tags) => (
                <Space size={[0, 8]} wrap>
                    {tags.map((tag, index) => (
                        <Tag key={index}>{tag}</Tag>
                    ))}
                </Space>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, blog) => (
                <Space>
                    <Button type="primary" onClick={() => handleEdit(blog)}>
                        Edit
                    </Button>
                    <Button danger onClick={() => handleDelete(blog)}>
                        Delete
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <Layout>
            <Layout>
                <Sider 
                    width={256}
                    collapsible 
                    collapsed={collapsed} 
                    onCollapse={(value) => setCollapsed(value)}
                    theme="light"
                    breakpoint="lg"
                >
                    <Sidebar />
                </Sider>
                <Content style={{ padding: '124px 24px 24px', minHeight: '100vh' }}>
                    <Title level={2}>Blog Management</Title>
                    
                    {/* Create Blog Form */}
                    <Card title="Create New Blog" style={{ marginBottom: 24 }}>
                        <Form
                            layout="vertical"
                            onFinish={handleSubmit}
                        >
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please input the title!' }]}
                            >
                                <Input 
                                    name="title"
                                    value={newBlog.title}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Content"
                                name="content"
                                rules={[{ required: true, message: 'Please input the content!' }]}
                            >
                                <TextArea
                                    rows={4}
                                    name="content"
                                    value={newBlog.content}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[{ required: true, message: 'Please input the category!' }]}
                            >
                                <Input
                                    name="category"
                                    value={newBlog.category}
                                    onChange={handleInputChange}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Tags"
                                name="tags"
                            >
                                <Input
                                    name="tags"
                                    value={newBlog.tags}
                                    onChange={handleInputChange}
                                    placeholder="Separate tags with commas"
                                />
                            </Form.Item>

                            <Form.Item
                                label="Status"
                                name="status"
                            >
                                <Select
                                    value={newBlog.status}
                                    onChange={(value) => handleInputChange({ target: { name: 'status', value }})}
                                >
                                    <Select.Option value="draft">Draft</Select.Option>
                                    <Select.Option value="published">Published</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Create Blog
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>

                    {/* Blog List */}
                    <Card title="Blog Posts">
                        <Table
                            columns={columns}
                            dataSource={blogs}
                            rowKey="_id"
                            pagination={{ pageSize: 10 }}
                        />
                    </Card>
                </Content>
            </Layout>

            {/* Edit Modal */}
            <Modal
                title="Edit Blog"
                open={isEditModalOpen}
                onCancel={() => {
                    setIsEditModalOpen(false);
                    setEditingBlog(null);
                }}
                footer={null}
            >
                {editingBlog && (
                    <Form
                        layout="vertical"
                        onFinish={handleUpdate}
                        initialValues={editingBlog}
                    >
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please input the title!' }]}
                        >
                            <Input
                                name="title"
                                onChange={handleEditInputChange}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Content"
                            name="content"
                            rules={[{ required: true, message: 'Please input the content!' }]}
                        >
                            <TextArea
                                rows={4}
                                name="content"
                                onChange={handleEditInputChange}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[{ required: true, message: 'Please input the category!' }]}
                        >
                            <Input
                                name="category"
                                onChange={handleEditInputChange}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Tags"
                            name="tags"
                        >
                            <Input
                                name="tags"
                                onChange={handleEditInputChange}
                                placeholder="Separate tags with commas"
                            />
                        </Form.Item>

                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Select
                                onChange={(value) => handleEditInputChange({ target: { name: 'status', value }})}
                            >
                                <Select.Option value="draft">Draft</Select.Option>
                                <Select.Option value="published">Published</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={updateLoading}>
                                {updateLoading ? 'Updating...' : 'Update Blog'}
                            </Button>
                        </Form.Item>
                    </Form>
                )}
            </Modal>
        </Layout>
    );
};

export default BlogPage;