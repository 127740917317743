import React, { useState, useEffect, useRef } from 'react';
import { Layout, Card, Row, Col, Button, Typography, Steps, Result, Divider, List, Form, Input, Radio, Space, message, Spin } from 'antd';
import { ShoppingCartOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { QRCodeCanvas } from 'qrcode.react';
import { jsPDF } from 'jspdf';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Step } = Steps;

const CONSUMER_KEY = '5zRTsaucTclqD23ZyPmLT9S1cnDUOch4efdoKP1Obwu1NS8d';
const CONSUMER_SECRET = 'OS0dWN6519MdAiULE3174c4oM2sMSG39TuJ08KdUzbbvw16bls4ADKq6xwAPbhd7';

// Add this function to test the auth string
const testAuth = () => {
  const auth = btoa(`${CONSUMER_KEY}:${CONSUMER_SECRET}`);
  console.log('Generated Auth String:', auth);
  return auth;
};

const Checkout = () => {
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [transactionId, setTransactionId] = useState('');
  const [form] = Form.useForm();
  const [paymentMethod, setPaymentMethod] = useState('mpesa');
  const [isLoading, setIsLoading] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const navigate = useNavigate();
  const [ticketQRData, setTicketQRData] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [checkoutRequestId, setCheckoutRequestId] = useState('');
  const qrCodeRef = useRef(null);

  const getAccessToken = async () => {
    try {
      console.log('Requesting access token...');
      
      // Use your backend proxy instead of calling Safaricom directly
      const response = await fetch('http://localhost:9000/api/mpesa/token');
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Token response:', data);

      if (data.access_token) {
        console.log('Access token received');
        setAccessToken(data.access_token);
        return data.access_token;
      } else {
        throw new Error('No access token in response');
      }
    } catch (error) {
      console.error('Error getting token:', error);
      throw error;
    }
  };

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        // Load cart data
        const savedCart = localStorage.getItem('cart');
        if (savedCart) {
          const parsedCart = JSON.parse(savedCart);
          setCart(parsedCart);
          const total = parsedCart.reduce((sum, item) => sum + item.totalAmount, 0);
          setTotalAmount(total);
        }

        // Get initial access token
        await getAccessToken();
      } catch (error) {
        console.error('Initialization error:', error);
        message.error('Failed to initialize. Please refresh the page.');
      }
    };

    loadInitialData();
  }, []);

  const generateTimestamp = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  };

  const generateTicketData = () => {
    const ticketId = `TIKO-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    const ticketData = {
      id: ticketId,
      event: cart[0]?.title, // Assuming first item is the event
      amount: totalAmount,
      date: new Date().toISOString(),
      transactionId: transactionId
    };
    return JSON.stringify(ticketData);
  };

  const handleProceedToPayment = async (values) => {
    try {
      setIsLoading(true);
      
      if (paymentMethod === 'mpesa') {
        const phoneNumber = values.phoneNumber.replace(/^0/, '254').replace(/[^0-9]/g, '');

        const payload = {
          phoneNumber: parseInt(phoneNumber),
          amount: Math.ceil(totalAmount),
        };

        console.log('Sending payment request:', payload);

        const response = await fetch('http://localhost:9000/api/mpesa/stkpush', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        });

        let data;
        try {
          const textResponse = await response.text();
          console.log('Raw response:', textResponse);
          
          try {
            data = JSON.parse(textResponse);
          } catch (parseError) {
            console.error('Failed to parse response:', parseError);
            throw new Error('Invalid response from server');
          }
        } catch (error) {
          console.error('Response error:', error);
          throw new Error('Failed to get response from server');
        }

        console.log('Parsed response:', data);

        if (data.success && data.CheckoutRequestID) {
          message.success('Please check your phone to complete the payment');
          setCheckoutRequestId(data.CheckoutRequestID);
          setCurrentStep(1);
          
          // Save the transaction via API
          const transactionPayload = {
            transactionId: data.CheckoutRequestID,
            amount: Math.ceil(totalAmount),
            phoneNumber: phoneNumber,
            eventTitle: cart[0]?.title || 'Event',
            status: 'pending'
          };

          await fetch('http://localhost:9000/api/transactions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(transactionPayload)
          });

          // Start polling for payment status
          checkPaymentStatus(data.CheckoutRequestID);
        } else {
          throw new Error(data.error || 'Payment initiation failed');
        }
      }
    } catch (error) {
      console.error('Payment error:', error);
      message.error(error.message || 'Payment failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const checkPaymentStatus = async (checkoutRequestId) => {
    try {
      let attempts = 0;
      const maxAttempts = 12; // 60 seconds (checking every 5 seconds)
      
      const checkStatus = async () => {
        if (attempts >= maxAttempts) {
          message.error('Payment timeout. Please try again.');
          navigate('/cart');
          return;
        }

        try {
          const response = await fetch(`http://localhost:9000/api/mpesa/status/${checkoutRequestId}`);
          const data = await response.json();
          console.log('Status check response:', data);

          if (data.success) {
            setIsPaymentConfirmed(true);
            setTransactionId(data.mpesaReceiptNumber);
            
            const ticketData = {
              tid: `T${Date.now()}`,  // Shorter ticket ID
              evt: cart[0]?.title.substring(0, 30),  // Truncate event name
              amt: data.amount,
              txn: data.mpesaReceiptNumber,
              ph: data.phoneNumber,
              dt: new Date().toISOString().split('T')[0],  // Just the date
              qty: cart.reduce((total, item) => total + item.quantity, 0)  // Total quantity
            };
            
            console.log('Generated ticket data:', ticketData);
            setTicketQRData(JSON.stringify(ticketData));
            setCurrentStep(1);
            message.success('Payment completed successfully!');
          } else if (data.failed) {
            if (data.reason === 'Request cancelled by user') {
              message.info('Payment cancelled by user');
              navigate('/cart');
            } else {
              attempts++;
              setTimeout(checkStatus, 5000);
            }
            return;
          } else {
            attempts++;
            setTimeout(checkStatus, 5000);
          }
        } catch (error) {
          console.error('Status check error:', error);
          attempts++;
          setTimeout(checkStatus, 5000);
        }
      };

      checkStatus();
    } catch (error) {
      console.error('Status check error:', error);
      message.error('Failed to verify payment status');
      navigate('/cart');
    }
  };

  const handleDownloadTicket = async () => {
    try {
      if (!ticketQRData) {
        message.error('Unable to generate ticket. Please try again.');
        return;
      }

      // Create new PDF document
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      // Add TikoEvents logo/text at the top
      doc.setFontSize(24);
      doc.setTextColor(0, 0, 0);
      doc.text('TikoEvents', 105, 20, { align: 'center' });

      // Add event details
      doc.setFontSize(16);
      doc.text('Event Ticket', 105, 40, { align: 'center' });
      doc.setFontSize(12);
      doc.text(`Event: ${cart[0]?.title || 'Event'}`, 20, 60);
      doc.text(`Transaction ID: ${transactionId}`, 20, 70);
      doc.text(`Date: ${new Date().toLocaleString()}`, 20, 80);
      doc.text(`Amount Paid: KSH ${totalAmount.toFixed(2)}`, 20, 90);

      // Fetch QR code image
      const qrUrl = `https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${encodeURIComponent(ticketQRData)}`;
      
      // Create an Image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      
      // Wait for image to load
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = qrUrl;
      });

      // Add QR code to PDF
      doc.addImage(img, 'PNG', 65, 100, 80, 80);

      // Add additional ticket details
      let yPos = 190;
      cart.forEach(item => {
        doc.text(`${item.title} - Quantity: ${item.quantity}`, 20, yPos);
        yPos += 10;
      });

      // Save the PDF
      doc.save(`ticket-${transactionId}.pdf`);
      message.success('Ticket downloaded successfully!');
    } catch (error) {
      console.error('Download error:', error);
      message.error('Failed to download ticket. Please try again.');
    }
  };

  const clearCartAndNavigate = () => {
    localStorage.removeItem('cart');
    navigate('/');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '24px', paddingTop: '96px' }}>
        <Row justify="center">
          <Col xs={24} md={20} lg={16}>
            <Card>
              <Steps current={currentStep} style={{ marginBottom: '24px' }}>
                <Step title="Checkout" icon={<ShoppingCartOutlined />} />
                <Step title="Confirmation" icon={<CheckCircleOutlined />} />
              </Steps>

              {currentStep === 0 ? (
                <>
                  <Title level={3}>Order Summary</Title>
                  <List
                    dataSource={cart}
                    renderItem={item => (
                      <List.Item>
                        <Row justify="space-between" style={{ width: '100%' }}>
                          <Col>
                            <Text strong>{item.title}</Text>
                            <br />
                            <Text type="secondary">Quantity: {item.quantity}</Text>
                          </Col>
                          <Col>
                            <Text>KSH {item.totalAmount?.toFixed(2)}</Text>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                  
                  <Divider />
                  
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleProceedToPayment}
                  >
                    <Title level={5}>Payment Method</Title>
                    <Form.Item>
                      <Radio.Group 
                        value={paymentMethod} 
                        onChange={(e) => setPaymentMethod(e.target.value)}
                      >
                        <Space direction="vertical">
                          <Radio value="mpesa">M-Pesa</Radio>
                          <Radio value="card">Credit/Debit Card</Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>

                    {paymentMethod === 'mpesa' && (
                      <Form.Item
                        name="phoneNumber"
                        label="M-Pesa Phone Number"
                        rules={[
                          { required: true, message: 'Please enter your phone number' },
                          { 
                            pattern: /^(?:\+254|254|0)[17][0-9]{8}$/,
                            message: 'Please enter a valid Kenyan phone number'
                          }
                        ]}
                      >
                        <Input 
                          placeholder="Enter phone number (e.g., 0712345678)"
                          maxLength={12}
                        />
                      </Form.Item>
                    )}

                    {paymentMethod === 'card' && (
                      <>
                        <Form.Item
                          name="cardNumber"
                          label="Card Number"
                          rules={[{ required: true, message: 'Please enter card number' }]}
                        >
                          <Input placeholder="Enter card number" />
                        </Form.Item>
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item
                              name="expiryDate"
                              label="Expiry Date"
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input placeholder="MM/YY" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              name="cvv"
                              label="CVV"
                              rules={[{ required: true, message: 'Required' }]}
                            >
                              <Input placeholder="CVV" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Divider />
                    
                    <Row justify="space-between">
                      <Col>
                        <Title level={4}>Total Amount:</Title>
                      </Col>
                      <Col>
                        <Title level={4}>KSH {totalAmount.toFixed(2)}</Title>
                      </Col>
                    </Row>

                    <Row gutter={16} justify="end" style={{ marginTop: '24px' }}>
                      <Col>
                        <Button onClick={() => navigate('/cart')}>
                          Back to Cart
                        </Button>
                      </Col>
                      <Col>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                          Pay Now
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              ) : (
                <>
                  {!isPaymentConfirmed ? (
                    <div style={{ textAlign: 'center', padding: '50px' }}>
                      <Spin size="large" />
                      <Title level={4} style={{ marginTop: '20px' }}>
                        Waiting for payment confirmation...
                      </Title>
                      <Text type="secondary">
                        Please complete the payment on your phone
                      </Text>
                    </div>
                  ) : (
                    <Result
                      status="success"
                      title="Payment Successful!"
                      subTitle={transactionId ? `M-Pesa Receipt Number: ${transactionId}` : 'Processing...'}
                      extra={[
                        <div key="qr" style={{ marginBottom: '24px', textAlign: 'center' }}>
                          <Title level={4}>Your Ticket QR Code</Title>
                          {ticketQRData && (
                            <div 
                              className="qr-code-container"
                              style={{ 
                                background: '#fff', 
                                padding: '20px', 
                                display: 'inline-block',
                                border: '1px solid #f0f0f0',
                                borderRadius: '4px'
                              }}
                            >
                              <QRCodeCanvas 
                                value={ticketQRData}
                                size={200}  // Reduced size
                                level="M"   // Medium error correction
                                includeMargin={true}
                              />
                            </div>
                          )}
                        </div>,
                        <Button 
                          key="download" 
                          type="primary" 
                          icon={<DownloadOutlined />}
                          onClick={handleDownloadTicket}
                          disabled={!ticketQRData}
                        >
                          Download Ticket
                        </Button>,
                        <Button 
                          key="home"
                          onClick={clearCartAndNavigate}
                        >
                          Back to Home
                        </Button>
                      ]}
                    >
                      <div style={{ textAlign: 'left', marginTop: '24px' }}>
                        <Title level={5}>Transaction Details</Title>
                        <Text>Date: {new Date().toLocaleString()}</Text>
                        <br />
                        <Text>Amount Paid: KSH {totalAmount.toFixed(2)}</Text>
                        <br />
                        <Text>Payment Method: M-Pesa</Text>
                        <br />
                        {ticketQRData && (
                          <Text>Phone Number: {JSON.parse(ticketQRData).phoneNumber}</Text>
                        )}
                        {cart.map((item, index) => (
                          <div key={index} style={{ marginTop: '12px' }}>
                            <Text strong>{item.title}</Text>
                            <br />
                            <Text type="secondary">Quantity: {item.quantity}</Text>
                          </div>
                        ))}
                      </div>
                    </Result>
                  )}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  );
};

export default Checkout;