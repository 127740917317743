import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { HomeOutlined, CalendarOutlined, ShoppingCartOutlined, UserOutlined, InfoCircleOutlined, ContactsOutlined, MenuOutlined, FileOutlined } from '@ant-design/icons';
import './Navbar.css';


const { Header } = Layout;

const Navbar = () => {
    const location = useLocation();
    const [scrolled, setScrolled] = useState(false);
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 10;
            setScrolled(isScrolled);
        };

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Scroll to top when location changes
        window.scrollTo(0, 0);
    }, [location]);

    const getMenuItems = () => {
        const baseItems = [
            { key: '/', label: <Link to="/" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Home</Link>, icon: <HomeOutlined style={{ color: 'black' }} /> },
            { key: '/about', label: <Link to="/about" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>About</Link>, icon: <InfoCircleOutlined style={{ color: 'black' }} /> },
        ];

        const offeringsItem = isMobile ? [
            { key: '/private-celebrations', label: <Link to="/private-celebrations" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Private Celebrations</Link>, icon: <UserOutlined style={{ color: 'black' }} /> },
            { key: '/brand-experience', label: <Link to="/brand-experience" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Brand Experience</Link>, icon: <UserOutlined style={{ color: 'black' }} /> },
            { key: '/gifting-concierge', label: <Link to="/gifting-concierge" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Gifting Concierge</Link>, icon: <UserOutlined style={{ color: 'black' }} /> },
        ] : [
            { 
                key: '/offerings', 
                label: <span style={{ color: 'black', fontFamily: 'Balerno' }}>Offerings</span>, 
                icon: <UserOutlined style={{ color: 'black' }} />,
                children: [
                    { key: '/private-celebrations', label: <Link to="/private-celebrations" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Private Celebrations</Link> },
                    { key: '/brand-experience', label: <Link to="/brand-experience" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Brand Experience</Link> },
                    { key: '/gifting-concierge', label: <Link to="/gifting-concierge" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Gifting Concierge</Link> },
                ]
            }
        ];

        const remainingItems = [
            //{ key: '/events', label: <Link to="/events" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Tickets</Link>, icon: <CalendarOutlined style={{ color: 'black' }} /> },
            { key: '/contact', label: <Link to="/contact" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Contact</Link>, icon: <ContactsOutlined style={{ color: 'black' }} /> },
            //{ key: '/sellers', label: <Link to="/sellers" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Sellers</Link>, icon: <UserOutlined style={{ color: 'black' }} /> },
            //{ key: '/blogs', label: <Link to="/blogs" style={{ textDecoration: 'none', color: 'black', fontFamily: 'Balerno' }}>Blogs</Link>, icon: <FileOutlined style={{ color: 'black' }} /> },
        ];

        return [...baseItems, ...offeringsItem, ...remainingItems];
    };

    return (
        <Header
            className={`custom-navbar ${scrolled ? 'scrolled' : ''}`}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                width: '100%',
                zIndex: 1000,
                padding: '0 16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '72px',
            }}
        >
            <div className="logo-container">
                <Link to="/" style={{ textDecoration: 'none', marginLeft: '0' }}>
                    <img 
                        src={require('../assets/logo.png')} 
                        alt="Logo" 
                        className="navbar-logo"
                        style={{ height: '180px' }} 
                    />
                </Link>
            </div>

            <Menu
                mode="horizontal"
                selectedKeys={[location.pathname]}
                items={getMenuItems()}
                style={{
                    flex: 1,
                    backgroundColor: 'white',
                    border: 'none',
                    color: 'black',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '@media (max-width: 768px)': {
                        display: 'none',
                    },
                }}
                theme="light"
                className="custom-menu desktop-menu"
            />

            <MenuOutlined 
                className="mobile-menu-button"
                onClick={() => setMobileMenuVisible(!mobileMenuVisible)}
                style={{ 
                    fontSize: '20px', 
                    display: 'none',
                    '@media (max-width: 768px)': {
                        display: 'block',
                    },
                }}
            />

            {/* Mobile Menu */}
            {mobileMenuVisible && (
                <Menu
                    mode="vertical"
                    selectedKeys={[location.pathname]}
                    items={getMenuItems()}
                    style={{
                        position: 'fixed',
                        top: '72px',
                        left: 0,
                        right: 0,
                        backgroundColor: 'white',
                        padding: '16px',
                        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        maxHeight: 'calc(100vh - 72px)',
                        overflowY: 'auto'
                    }}
                    className="mobile-menu"
                />
            )}

            <Link to="/login" style={{ marginLeft: 'auto', marginRight: '0' }}>
                <UserOutlined style={{ fontSize: '20px', color: 'black' }} />
            </Link>
        </Header>
    );
};

export default Navbar;
