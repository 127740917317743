import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, message, Select, Typography } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import axios from 'axios';

const { Option } = Select;
const { Title } = Typography;

const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://api.unsplash.com/photos/random', {
          params: {
            count: 5,
            query: 'Black people Social Events (Birthday, Engagement, Anniversary)',
            client_id: process.env.REACT_APP_UNSPLASH_ACCESS_KEY
          },
          headers: {
            'Authorization': `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
          }
        });
        setImages(response.data);
      } catch (error) {
        console.error('Error fetching images:', error);
        // Fallback to a default image array if Unsplash fails
        setImages([{
          urls: { regular: 'https://images.unsplash.com/photo-1523906834658-6e24ef2386f9' },
          alt_description: 'Default signup background'
        }]);
      }
    };

    fetchImages();
  }, []);

  const handleError = (error) => {
    if (error.response) {
      // Server responded with error
      if (error.response.status === 409) {
        message.error('Email already exists. Please use a different email.');
      } else if (error.response.data?.errors) {
        // Validation errors from express-validator
        const validationErrors = error.response.data.errors;
        validationErrors.forEach(err => {
          message.error(err.msg);
        });
      } else {
        message.error(error.response.data?.message || 'Signup failed');
      }
    } else if (error.request) {
      // Request made but no response received
      message.error('Cannot connect to server. Please try again later.');
    } else {
      // Error in request setup
      message.error('An error occurred. Please try again.');
    }
    console.error('Signup Error:', error);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(`${apiUrl}/api/users/signup`, values, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      
      if (response.data?.success) {
        message.success('Signup successful! Please login.');
        form.resetFields();
        window.location.href = '/login';
      } else {
        message.warning('Signup completed but with unexpected response.');
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex', position: 'relative' }}>
      <div style={{ 
        position: 'absolute',
        left: 0,
        top: 0,
        width: '75%',
        height: '100%',
        backgroundColor: '#f5f5f5',
        overflow: 'hidden'
      }}>
        {images.length > 0 ? (
          <div style={{ position: 'relative', height: '100%' }}>
            <img 
              src={images[0].urls.regular}
              alt={images[0].alt_description || "Signup background"}
              style={{ 
                width: '100%', 
                height: '100%', 
                objectFit: 'cover',
                filter: 'brightness(0.4)'
              }} 
            />
            <div style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              width: '100%',
              padding: '0 20px'
            }}>
              <Title style={{ 
                color: 'white', 
                marginBottom: '16px',
                fontSize: '88px',
                fontFamily: 'Balerno, sans-serif'
              }}>
                Welcome to Zamari
              </Title>
            </div>
          </div>
        ) : (
          <div style={{ 
            width: '100%', 
            height: '100%', 
            backgroundColor: '#f5f5f5' 
          }} />
        )}
      </div>

      <div style={{ 
        marginLeft: 'auto',
        width: '25%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 20px',
        minWidth: '340px'
      }}>
        <Card 
          title="Sign Up" 
          bordered={false} 
          style={{ 
            width: 300,
            boxShadow: 'none',
            backgroundColor: 'transparent'
          }}
        >
          <Form
            form={form}
            name="signup"
            onFinish={onFinish}
            onFinishFailed={(errorInfo) => {
              message.error('Please check your input and try again.');
            }}
            autoComplete="off"
            style={{ width: '100%' }}
          >
            <Form.Item
              name="name"
              rules={[
                { required: true, message: 'Please input your name!' },
                { min: 2, message: 'Name must be at least 2 characters!' },
                { whitespace: true, message: 'Name cannot be empty!' }
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please input your email!' },
                { type: 'email', message: 'Please enter a valid email!' },
                { whitespace: true, message: 'Email cannot be empty!' }
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
                { min: 6, message: 'Password must be at least 6 characters!' },
                { whitespace: true, message: 'Password cannot be empty!' }
              ]}
            >
              <Input.Password prefix={<LockOutlined />} placeholder="Password" />
            </Form.Item>

            <Form.Item
              name="phone_number"
              rules={[
                { required: true, message: 'Please input your phone number!' },
                { pattern: /^\+?[\d\s-]+$/, message: 'Please enter a valid phone number!' },
                { whitespace: true, message: 'Phone number cannot be empty!' }
              ]}
            >
              <Input prefix={<PhoneOutlined />} placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              name="role"
              rules={[{ required: true, message: 'Please select a role!' }]}
            >
              <Select placeholder="Select role">
                <Option value="customer">Customer</Option>
                <Option value="seller">Seller</Option>
              </Select>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block loading={loading}>
                Sign Up
              </Button>
            </Form.Item>
            
            <div style={{ textAlign: 'center' }}>
              Already have an account? <Link to="/login">Login</Link>
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default Signup; 