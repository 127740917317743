import React, { useState, useEffect } from 'react';
import { Layout, Card, Row, Col, Button, Typography, Steps, Result, Divider, List, Space, message } from 'antd';
import { ShoppingCartOutlined, CheckCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { QRCodeCanvas } from 'qrcode.react';
import { jsPDF } from 'jspdf';
import axios from 'axios';

const { Content } = Layout;
const { Title, Text } = Typography;
const { Step } = Steps;

const DebugQRCode = ({ value }) => {
  console.log('Rendering QR code with value:', value);
  return (
    <QRCodeCanvas 
      value={value}
      size={256}
      level="H"
      includeMargin={true}
    />
  );
};

const TestCheckout = () => {
  const [cart, setCart] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const [transactionId, setTransactionId] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [ticketQRData, setTicketQRData] = useState('');
  const navigate = useNavigate();

  React.useEffect(() => {
    const savedCart = localStorage.getItem('cart');
    if (savedCart) {
      const parsedCart = JSON.parse(savedCart);
      setCart(parsedCart);
      const total = parsedCart.reduce((sum, item) => sum + item.totalAmount, 0);
      setTotalAmount(total);
    }
  }, []);

  useEffect(() => {
    console.log('ticketQRData changed:', ticketQRData);
  }, [ticketQRData]);

  const handleTestPayment = async () => {
    try {
      // Generate test transaction data
      const testTransactionId = `TEST-${Date.now().toString(36)}`;
      console.log('Generated test transaction ID:', testTransactionId);

      const ticketData = {
        id: `TIKO-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
        event: cart[0]?.title || 'Test Event',
        amount: totalAmount,
        transactionId: testTransactionId,
        phoneNumber: '254700000000',
        date: new Date().toISOString(),
        items: cart.map(item => ({
          title: item.title,
          quantity: item.quantity
        }))
      };

      console.log('Generated ticket data:', ticketData);
      const ticketQRString = JSON.stringify(ticketData);
      console.log('Setting QR string:', ticketQRString);

      // Post transaction to backend
      await axios.post('/api/transactions', {
        transactionId: testTransactionId,
        amount: totalAmount,
        phoneNumber: '254700000000',
        eventTitle: cart[0]?.title || 'Test Event',
        status: 'completed'
      });

      setTransactionId(testTransactionId);
      setTicketQRData(ticketQRString);
      setIsPaymentConfirmed(true);
      setCurrentStep(1);
      message.success('Test payment completed successfully!');
    } catch (error) {
      console.error('Error generating test payment:', error);
      message.error('Failed to generate test payment');
    }
  };

  const handleDownloadTicket = async () => {
    try {
      if (!ticketQRData) {
        message.error('Unable to generate ticket. Please try again.');
        return;
      }

      // Create new PDF document
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4'
      });

      // Add TikoEvents logo/text at the top
      doc.setFontSize(24);
      doc.setTextColor(0, 0, 0);
      doc.text('TikoEvents', 105, 20, { align: 'center' });

      // Add event details
      doc.setFontSize(16);
      doc.text('Event Ticket', 105, 40, { align: 'center' });
      doc.setFontSize(12);
      doc.text(`Event: ${cart[0]?.title || 'Event'}`, 20, 60);
      doc.text(`Transaction ID: ${transactionId}`, 20, 70);
      doc.text(`Date: ${new Date().toLocaleString()}`, 20, 80);
      doc.text(`Amount Paid: KSH ${totalAmount.toFixed(2)}`, 20, 90);

      // Fetch QR code image
      const qrUrl = `https://api.qrserver.com/v1/create-qr-code/?size=256x256&data=${encodeURIComponent(ticketQRData)}`;
      
      // Create an Image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      
      // Wait for image to load
      await new Promise((resolve, reject) => {
        img.onload = resolve;
        img.onerror = reject;
        img.src = qrUrl;
      });

      // Add QR code to PDF
      doc.addImage(img, 'PNG', 65, 100, 80, 80);

      // Add additional ticket details
      let yPos = 190;
      cart.forEach(item => {
        doc.text(`${item.title} - Quantity: ${item.quantity}`, 20, yPos);
        yPos += 10;
      });

      // Save the PDF
      doc.save(`ticket-${transactionId}.pdf`);
      message.success('Ticket downloaded successfully!');
    } catch (error) {
      console.error('Download error:', error);
      message.error('Failed to download ticket. Please try again.');
    }
  };

  const clearCartAndNavigate = () => {
    localStorage.removeItem('cart');
    navigate('/');
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Navbar />
      <Content style={{ padding: '24px', paddingTop: '96px' }}>
        <Row justify="center">
          <Col xs={24} md={20} lg={16}>
            <Card>
              <Steps current={currentStep} style={{ marginBottom: '24px' }}>
                <Step title="Checkout" icon={<ShoppingCartOutlined />} />
                <Step title="Confirmation" icon={<CheckCircleOutlined />} />
              </Steps>

              {currentStep === 0 ? (
                <>
                  <Title level={3}>Order Summary</Title>
                  <List
                    dataSource={cart}
                    renderItem={item => (
                      <List.Item>
                        <Row justify="space-between" style={{ width: '100%' }}>
                          <Col>
                            <Text strong>{item.title}</Text>
                            <br />
                            <Text type="secondary">Quantity: {item.quantity}</Text>
                          </Col>
                          <Col>
                            <Text>KSH {item.totalAmount?.toFixed(2)}</Text>
                          </Col>
                        </Row>
                      </List.Item>
                    )}
                  />
                  
                  <Divider />
                  
                  <Row justify="space-between">
                    <Col>
                      <Title level={4}>Total Amount:</Title>
                    </Col>
                    <Col>
                      <Title level={4}>KSH {totalAmount.toFixed(2)}</Title>
                    </Col>
                  </Row>

                  <Row gutter={16} justify="end" style={{ marginTop: '24px' }}>
                    <Col>
                      <Button onClick={() => navigate('/cart')}>
                        Back to Cart
                      </Button>
                    </Col>
                    <Col>
                      <Button type="primary" onClick={handleTestPayment}>
                        Test Payment
                      </Button>
                    </Col>
                  </Row>
                </>
              ) : (
                <Result
                  status="success"
                  title="Payment Successful!"
                  subTitle={`Test Receipt Number: ${transactionId}`}
                  extra={[
                    <div key="qr" style={{ marginBottom: '24px', textAlign: 'center' }}>
                      <Title level={4}>Your Ticket QR Code</Title>
                      <div 
                        className="qr-code-container"
                        style={{ 
                          background: '#fff', 
                          padding: '20px', 
                          display: 'inline-block',
                          border: '1px solid #f0f0f0',
                          borderRadius: '4px'
                        }}
                      >
                        {ticketQRData && (
                          <QRCodeCanvas 
                            value={ticketQRData}
                            size={256}
                            level="H"
                            includeMargin={true}
                          />
                        )}
                      </div>
                    </div>,
                    <Button 
                      key="download" 
                      type="primary" 
                      icon={<DownloadOutlined />}
                      onClick={handleDownloadTicket}
                      disabled={!ticketQRData}
                    >
                      Download Ticket
                    </Button>,
                    <Button 
                      key="home"
                      onClick={clearCartAndNavigate}
                    >
                      Back to Home
                    </Button>
                  ]}
                >
                  <div style={{ textAlign: 'left', marginTop: '24px' }}>
                    <Title level={5}>Transaction Details</Title>
                    <Text>Date: {new Date().toLocaleString()}</Text>
                    <br />
                    <Text>Amount Paid: KSH {totalAmount.toFixed(2)}</Text>
                    <br />
                    <Text>Payment Method: Test Payment</Text>
                    <br />
                    {ticketQRData && (
                      <Text>Phone Number: {JSON.parse(ticketQRData).phoneNumber}</Text>
                    )}
                    {cart.map((item, index) => (
                      <div key={index} style={{ marginTop: '12px' }}>
                        <Text strong>{item.title}</Text>
                        <br />
                        <Text type="secondary">Quantity: {item.quantity}</Text>
                      </div>
                    ))}
                  </div>
                </Result>
              )}
            </Card>
          </Col>
        </Row>
      </Content>
      <Footer />
    </Layout>
  );
};

export default TestCheckout; 