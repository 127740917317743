import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/index';
import Events from './pages/events';
import EventDetails from './pages/event/[slug]';
import Login from './components/Auth/login';
import Signup from './components/Auth/signup';
import Contact from './pages/contact';
import BlogPost from './pages/blog/[slug]';
import Sellercart from './pages/sellers';
import Checkout from './pages/Checkout';
import 'antd/dist/reset.css';
import TestCheckout from './pages/TestCheckout';
import './styles/globals.css';
import About from './pages/About';
import BrandExperience from './pages/BrandExperience';
import PrivateCelebrations from './pages/PrivateCelebrations';
import GiftingConcierge from './pages/GiftingConcierge';
import BlogPage from './pages/BlogPage';

// Create a wrapper component that handles the scroll behavior
function ScrollWrapper({ children }) {
    const location = useLocation();
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]); // Scroll to top when pathname changes
    
    return children;
}

function App() {
    return (
        <div className="w-full overflow-x-hidden">
            <BrowserRouter>
                <ScrollWrapper>
                    <div className="app-container">
                        <Routes>
                            {/* Public routes */}
                            <Route path="/" element={
                                <>
                                    <Home />
                                    <div id="top-anchor" />
                                </>
                            } />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/events" element={<Events />} />
                            <Route path="/event/:slug" element={<EventDetails />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/blog/:slug" element={<BlogPost />} />
                            
                            {/* Routes that may need protection */}
                            <Route path="/blogs" element={<BlogPage />} />
                            <Route path="/sellers" element={<Sellercart />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/test-checkout" element={<TestCheckout />} />
                            <Route path="/brand-experience" element={<BrandExperience />} />
                            <Route path="/private-celebrations" element={<PrivateCelebrations />} />
                            <Route path="/gifting-concierge" element={<GiftingConcierge />} />  
                        </Routes>
                    </div>
                </ScrollWrapper>
            </BrowserRouter>
        </div>
    );
}

export default App;

