import React from 'react';
import { Card, Col, Space, Tag, Typography } from 'antd';
import { CalendarOutlined, EnvironmentOutlined, DollarOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

const { Text } = Typography;

const EventsCard2 = ({ event, actions }) => {
    const navigate = useNavigate();
    
    return (
        <Col 
            xs={24} 
            sm={12} 
            lg={8}
            style={{ display: 'flex' }}
            key={event.id}
        >
            <Card
                hoverable
                style={{ 
                    width: '85%',
                    fontSize: '0.9em',
                    margin: '0',
                    padding: '0',
                    fontFamily: 'Balerno'
                }}
                cover={
                    <img 
                        src={event.image || 'https://via.placeholder.com/300x200'}
                        alt={event.title}
                        style={{ 
                            objectFit: 'cover',
                            height: 140,
                            width: '100%'
                        }}
                    />
                }
                actions={actions}
                onClick={(e) => {
                    e.preventDefault();
                    navigate(`/event/${event.slug || event._id}`);
                  }}
            >
                <Tag color="blue" style={{ marginBottom: '8px', fontFamily: 'Balerno' }}>
                    {event.category.charAt(0).toUpperCase() + event.category.slice(1)}
                </Tag>
                <Card.Meta
                    title={<div style={{ fontSize: '1.5em', fontFamily: 'Balerno' }}>{event.title}</div>}
                    description={
                        <Space direction="vertical" size={1}>
                            <Text style={{ fontSize: '0.9em', fontFamily: 'Balerno' }}>
                                <CalendarOutlined /> {new Date(event.date).toLocaleDateString()} {event.time}
                            </Text>
                            <Text style={{ fontSize: '0.9em', fontFamily: 'Balerno' }}>
                                <EnvironmentOutlined /> {event.location || 'Location TBA'}
                            </Text>
                            <Text strong style={{ fontSize: '1.1em', fontFamily: 'Balerno' }}>
                                <DollarOutlined /> KSH {event.price_per_ticket.toLocaleString()}
                            </Text>


                        </Space>
                    }
                />
            </Card>
        </Col>
    );
};

export default EventsCard2; 