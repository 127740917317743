// Add these import statements at the top of your file
import Navbar from '../components/Navbar';
import homeImage from '../assets/Pri/corp.jpg';
import { Typography, Button } from 'antd';
import Footer from '../components/Footer';
import brandVideo from '../assets/Pri/bra1.mp4';

const { Title, Paragraph } = Typography;

// ... existing code ...
const BrandExperience = () => {
    return (
        <>
            <Navbar />
            <div className="gifting-page" style={{ backgroundColor: '#fff', fontFamily: 'Balerno, serif' }}>
            <section 
                    className="hero-section" 
                    style={{ 
                        textAlign: 'center', 
                        position: 'relative',
                        padding: '200px 0',
                        color: '#fff',
                        marginTop: '72px',
                        fontFamily: 'Balerno, serif',
                        overflow: 'hidden'
                    }}
                >
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${homeImage})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'blur(4px)',
                        transform: 'scale(1.1)', // Prevents blur edges from showing
                        zIndex: 1
                    }} />
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <Title level={1} style={{ marginBottom: '20px', color: '#fff', fontSize: '48px', fontFamily: 'Balerno, serif' }}>
                            Corporate Elegance: Zamari Conferences and Meetings
                        </Title>
                    </div>
                </section>

                <section style={{ padding: '80px 20px', textAlign: 'center' }}>
                    <h1 level={2} style={{ marginBottom: '40px', color: '#000', fontSize: '36px', fontFamily: 'Balerno, serif' }}>
                        Curated Brand Experiences
                    </h1>
                    <Paragraph style={{
                        fontSize: '18px',
                        maxWidth: '800px',
                        margin: '0 auto 40px',
                        lineHeight: '1.6',
                        color: '#000',
                        fontFamily: 'Balerno, serif'
                    }}>
                        We can either collaborate or set up corporate events to achieve the same goals for content and gallery.
                    </Paragraph>
                    <Button 
                        type="text" 
                        size="large"
                        style={{
                            height: '48px',
                            padding: '0 32px',
                            fontSize: '16px',
                            color: 'gold',
                            backgroundColor: 'transparent',
                            border: '1px solid gold',
                            transition: 'all 0.3s ease',
                            fontFamily: 'Balerno, serif'
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = 'rgba(255, 215, 0, 0.1)'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                    >
                        BOOK A CONSULTATION
                    </Button>
                </section>
                <section>
                    <Title level={3} style={{ textAlign: 'center', marginBottom: '20px', color: '#000', fontFamily: 'Balerno, serif' }}>
                        Past Collaborations
                    </Title>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '40px 20px',
                        maxWidth: '1200px',
                        margin: '0 auto'
                    }}>
                        <div style={{
                            width: '300px', // Fixed width
                            height: '534px', // 300 * (16/9) for Instagram Reel aspect ratio
                            position: 'relative',
                            overflow: 'hidden'
                        }}>
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            >
                                <source src={brandVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                    </div>
                </section>
                
                      
            </div>
            <Footer />
        </>
    );
}

export default BrandExperience;
// ... existing code ...