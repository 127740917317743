import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Button, Row, Col, Carousel } from 'antd';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import home1Image from '../assets/picnics/pic7.jpg';
import home2Image from '../assets/picnics/pic4.jpg';
import home3Image from '../assets/picnics/pic11.jpg';
import home4Image from '../assets/garland/gar21.jpg';
import about1 from '../assets/home/home6.jpg';
import review1 from '../assets/reviews/1r.jpg';
import review2 from '../assets/reviews/2r.jpg';
import review3 from '../assets/reviews/3r.jpg';
import review4 from '../assets/reviews/4r.jpg';


const { Title, Text, Paragraph } = Typography;

const LandingPage = () => {
    const navigate = useNavigate();

    const carouselImages = [
        {
            image: home1Image,
            title: "Making Memories, One Event at a Time",
            subtitle: "Turning Dreams into Timeless Moments"
        },
        {
            image: home2Image,
            title: "Crafting Unforgettable Experiences",
            subtitle: "Where Every Detail Tells Your Story"
        },
        {
            image: home3Image,
            title: "Celebrating Life's Special Moments",
            subtitle: "Creating Magic Through Perfect Planning"
        },
        {
            image: home4Image,
            title: "Your Vision, Our Expertise",
            subtitle: "Bringing Dreams to Life with Excellence"
        }
    ];

    const AnimatedNumber = ({ end }) => {
        const [count, setCount] = useState(0);

        useEffect(() => {
            let start = 0;
            const duration = 2000; // 2 seconds
            const frameDuration = 1000/60; // 60fps
            const totalFrames = Math.round(duration/frameDuration);
            const increment = end/totalFrames;

            const counter = setInterval(() => {
                start += increment;
                if (start > end) {
                    setCount(end);
                    clearInterval(counter);
                } else {
                    setCount(Math.floor(start));
                }
            }, frameDuration);

            return () => clearInterval(counter);
        }, [end]);

        return <span>{count}+</span>;
    };

    return (
        <>
            <Navbar />
            <div className="bg-white" style={{ 
                overflowX: 'hidden',
                margin: 0,
                padding: 0,
                width: '100%',
                paddingTop: '64px',
                fontFamily: 'Balerno, serif'
            }}>
                <Carousel
                    autoplay
                    effect="fade"
                    dots={{ className: 'custom-dots' }}
                    className="hero-carousel"
                    autoplaySpeed={2000}
                    pauseOnHover={true}
                    style={{ fontFamily: 'Balerno, serif' }}
                >
                    {carouselImages.map((slide, index) => (
                        <div key={index}>
                            <div 
                                className="relative bg-cover bg-center carousel-slide"
                                style={{
                                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)), url(${slide.image})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    height: window.innerWidth <= 768 ? '75vh' : '86vh',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontFamily: 'Balerno, serif',
                                    animation: 'zoomEffect 20s infinite',
                                    transformOrigin: 'center center'
                                }}
                            >
                                <div className="text-center text-white px-4">
                                    <h1 
                                        className="font-bold mb-4" 
                                        style={{ 
                                            fontFamily: 'Balerno, serif',
                                            fontSize: window.innerWidth <= 768 ? '2rem' : '4rem',
                                            color: 'white'
                                        }}
                                    >
                                        {slide.title}
                                    </h1>
                                    <p 
                                        className="mb-8" 
                                        style={{ 
                                            fontFamily: 'Balerno, serif',
                                            fontSize: window.innerWidth <= 768 ? '1rem' : '1.5rem',
                                            color: 'white'
                                        }}
                                    >
                                        {slide.subtitle}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>

                <style>
                    {`
                        @keyframes zoomEffect {
                            0% {
                                transform: scale(1);
                            }
                            50% {
                                transform: scale(1.1);
                            }
                            100% {
                                transform: scale(1);
                            }
                        }
                    `}
                </style>

                <section style={{ padding: '24px 16px', backgroundColor: '#f7f7f7' }}>
                    <Row justify="center">
                        <Col xs={24} sm={22} md={16} lg={14}>
                            <Title level={2} style={{ 
                                textAlign: 'center', 
                                marginBottom: '32px',
                                fontFamily: 'Balerno',
                                fontSize: window.innerWidth <= 768 ? '24px' : '32px'
                            }}>
                                <strong>Welcome to ZaMaRi Events</strong>
                            </Title>
                            <Paragraph style={{ 
                                fontSize: window.innerWidth <= 768 ? '20px' : '30px',
                                lineHeight: '1.3',
                                textAlign: 'center', 
                                fontFamily: 'Balerno, serif', 
                                color: '#CDAD72',
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                fontWeight: 400,
                                fontStyle: 'normal',
                                fontVariant: 'normal',
                                fontKerning: 'auto',
                                fontOpticalSizing: 'auto',
                                fontStretch: '100%',
                                fontVariationSettings: 'normal',
                                fontFeatureSettings: 'normal',
                                textTransform: 'uppercase',
                                textDecoration: 'none',
                                textIndent: '0px'
                            }}>
                                At the heart of Zamari Events is a commitment to excellence and a genuine love for orchestrating moments that matter. 
                            </Paragraph>
                            <Paragraph style={{ 
                                fontSize: window.innerWidth <= 768 ? '20px' : '30px',
                                lineHeight: '1.5', 
                                textAlign: 'center', 
                                fontFamily: 'Cormorant Garamond, serif', 
                                color: '#505050', 
                                backgroundColor: 'rgba(0, 0, 0, 0)', 
                                fontWeight: 300, 
                                fontStyle: 'normal', 
                                fontVariant: 'normal', 
                                fontKerning: 'auto', 
                                fontOpticalSizing: 'auto', 
                                fontStretch: '100%', 
                                fontVariationSettings: 'normal', 
                                fontFeatureSettings: 'normal', 
                                textTransform: 'none', 
                                textDecoration: 'none', 
                                textIndent: '0px'
                            }}>
                                We pride ourselves on being more than just planners; we are storytellers, narrating your unique tale through meticulously curated events. Whether it's the joyous union of two souls, a corporate gathering that leaves a lasting impression, or a celebration of life's milestones, Zamari Events is here to make your dreams come true.                             </Paragraph>
                        </Col>
                    </Row>
                </section>

                <section style={{ padding: '48px 0', backgroundColor: '#f8f9fa', fontFamily: 'Balerno, serif' }}>
                    <Row justify="center">
                        <Col xs={22} sm={20} md={16} lg={14}>
                            <Title 
                                level={2} 
                                style={{ 
                                    textAlign: 'center', 
                                    marginBottom: '24px', 
                                    fontFamily: 'Balerno, serif',
                                    color: 'rgb(80, 80, 80)',
                                    backgroundColor: 'rgba(0, 0, 0, 0)'
                                }}
                            >
                            THE BRAND
                            </Title>
                            <Row align="middle" gutter={[48, 48]} style={{ marginBottom: '48px' }}>
                                <Col xs={24} md={12} order={{ xs: 2, md: 1 }}>
                                    <img
                                        src={about1}
                                        alt="Tiko Vision"
                                        className="default-image-height"
                                        style={{
                                            width: '100%',
                                            objectFit: 'cover'
                                        }}
                                    />
                                </Col>
                                <Col xs={24} md={12} order={{ xs: 1, md: 2 }}>
                                    <Paragraph style={{ fontSize: '20px', lineHeight: '1.8', fontFamily: 'Balerno, serif' }}>
                                        <strong>MAKING MEMORIES, ONE EVENT AT A TIME</strong><br/><br/>
                                        With exceptional attention to detail and personalized service, we bring your vision to life through our passion, expertise, and professional approach. Trust us to create an unforgettable celebration that you and your guests will cherish forever.<br/><br/>
                                        Zamari Events is a dynamic event planning company that specializes in creating beautiful celebrations. We work closely with each client to design unique experiences that perfectly match their style and vision. Our dedicated team brings creativity and expertise to every event, ensuring your special day exceeds all expectations.<br/><br/>
                                        We take pride in our relationships with quality vendors and creative partners who share our commitment to excellence and attention to detail.
                                    </Paragraph>
                                    <Button 
                                        type="text" 
                                        href="/about"
                                        style={{ 
                                            marginTop: '16px', 
                                            border: '1px solid transparent', 
                                            color: '#CDAD72', 
                                            transition: 'all 0.3s ease',
                                            fontFamily: 'Balerno, serif'
                                        }}
                                        onMouseEnter={(e) => e.currentTarget.style.border = '1px solid #CDAD72'}
                                        onMouseLeave={(e) => e.currentTarget.style.border = '1px solid transparent'}
                                    >
                                        Learn More
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </section>

                <section style={{ textAlign: 'center', padding: '48px 16px' }}>
                    <div style={{ maxWidth: '1200px', margin: '0 auto' }}>
                        <Title level={2} style={{ marginBottom: '32px', fontFamily: 'Balerno, serif' }}>Our Impact</Title>
                        <Row gutter={[24, 24]} justify="center">
                            <Col xs={24} sm={12} md={8}>
                                
                                <Title level={2} style={{ 
                                    color: 'black', 
                                    marginBottom: '8px', 
                                    fontFamily: 'Balerno, serif',
                                    fontStyle: 'bold',
                                    fontSize: '2.5rem'
                                }}>
                                    <AnimatedNumber end={50} />
                                </Title>
                                <Paragraph style={{ 
                                    fontFamily: 'Balerno, serif',
                                    fontSize: '1.1rem',
                                    fontStyle: 'bold',
                                    color: '#666'
                                    
                                }}>
                                    Events Organized
                                </Paragraph>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                
                                <Title level={2} style={{ 
                                    color: 'black', 
                                    marginBottom: '8px', 
                                    fontFamily: 'Balerno, serif',
                                    fontSize: '2.5rem'
                                }}>
                                    <AnimatedNumber end={3} />
                                </Title>
                                <Paragraph style={{ 
                                    fontFamily: 'Balerno, serif',
                                    fontSize: '1.1rem',
                                    color: '#666'
                                }}>
                                    Event Partners
                                </Paragraph>
                            </Col>
                            <Col xs={24} sm={12} md={8}>
                                
                                <Title level={2} style={{ 
                                    color: 'black', 
                                    marginBottom: '8px', 
                                    fontFamily: 'Balerno, serif',
                                    fontStyle: 'bold',
                                    fontSize: '2.5rem'
                                }}>
                                    <AnimatedNumber end={50} />
                                </Title>
                                <Paragraph style={{ 
                                    fontFamily: 'Balerno, serif',
                                    fontSize: '1.1rem',
                                    color: '#666'
                                }}>
                                    Successful Events
                                </Paragraph>
                            </Col>
                        </Row>
                    </div>
                </section>

                <section style={{ 
                    padding: '48px 24px 64px',
                    background: '#f8f9fa',
                    fontFamily: 'Balerno, serif'
                }}>
                    <div style={{ 
                        maxWidth: '1200px', 
                        margin: '0 auto',
                        textAlign: 'center',
                        marginBottom: '48px'
                    }}>
                        <Title level={2} style={{
                            fontSize: '2rem',
                            marginBottom: '16px',
                            fontFamily: 'Balerno, serif'
                        }}>
                            Client Feedback
                        </Title>
                        <Text type="secondary" style={{ 
                            fontSize: '1.1rem',
                            display: 'block',
                            fontFamily: 'Balerno, serif'
                        }}>
                            Real conversations with our satisfied customers
                        </Text>
                    </div>

                    <div style={{ 
                        maxWidth: '1200px', 
                        margin: '0 auto',
                        overflowX: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        scrollbarWidth: 'none', // Firefox
                        msOverflowStyle: 'none', // IE/Edge
                        '&::-webkit-scrollbar': { // Chrome/Safari
                            display: 'none'
                        }
                    }}>
                        <div style={{ 
                            display: 'flex',
                            gap: '32px',
                            paddingBottom: '20px', // Space for potential scrollbar
                            minWidth: 'min-content'
                        }}>
                            {[
                                {
                                    image: review1,
                                    altText: "Client review screenshot 1"
                                },
                                {
                                    image: review2,
                                    altText: "Client review screenshot 2"
                                },
                                {
                                    image: review3,
                                    altText: "Client review screenshot 3"
                                },
                                {
                                    image: review4,
                                    altText: "Client review screenshot 4"
                                }
                            ].map((screenshot, index) => (
                                <div key={index} style={{
                                    flex: '0 0 auto',
                                    width: '300px', // Fixed width for each screenshot
                                    backgroundColor: 'white',
                                    borderRadius: '12px',
                                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                                    overflow: 'hidden',
                                    cursor: 'pointer',
                                    transition: 'transform 0.2s ease-in-out'
                                }}
                                onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
                                onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                                >
                                    <img 
                                        src={screenshot.image} 
                                        alt={screenshot.altText}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'block'
                                        }}
                                        onClick={() => window.open(screenshot.image, '_blank')}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        </>
    );
};

export default LandingPage;
