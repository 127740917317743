import React from 'react';
import { Menu, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { 
  CalendarOutlined, 
  UserOutlined, 
  SettingOutlined,
  FileOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState(false);

  const menuItems = [

    {
        key: 'Sellers',
        icon: <CalendarOutlined />,
        label: 'Admin',
        onClick: () => navigate('/sellers')
      },
    {
      key: 'blogs',
      icon: <FileOutlined />,
      label: 'Blogs',
      onClick: () => navigate('/blogs')
    },
    {
      key: 'profile',
      icon: <UserOutlined />,
      label: 'Profile',
      onClick: () => navigate('/profile')
    },
    {
      key: 'settings',
      icon: <SettingOutlined />,
      label: 'Settings',
      onClick: () => navigate('/settings')
    }
  ];

  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <div className="logo-container">
          <img 
            src={require('../assets/logo.png')} 
            alt="Logo" 
            className="navbar-logo"
            style={{ height: collapsed ? '64px' : '180px', transition: 'height 0.3s' }} 
          />
      </div>
      <Menu
        mode="inline"
        defaultSelectedKeys={['events']}
        items={menuItems}
        style={{ border: 'none' }}
      />
    </Layout.Sider>
  );
}

export default Sidebar; 