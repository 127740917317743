import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, Row, Col, Typography, Spin, Alert, Space, Tag } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

const { Content } = Layout;
const { Title, Text, Paragraph } = Typography;

const BlogDetail = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await fetch(`/api/blogs/${slug}`);
                if (!response.ok) throw new Error('Failed to fetch blog');
                const data = await response.json();
                setBlog(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        fetchBlog();
    }, [slug]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Navbar />
            <Content style={{ padding: '48px 24px' }}>
                <Row justify="center">
                    <Col xs={24} sm={20} md={16} lg={12}>
                        {isLoading ? (
                            <Row justify="center" style={{ padding: '48px' }}>
                                <Spin size="large" />
                            </Row>
                        ) : error ? (
                            <Alert
                                message="Error"
                                description={error}
                                type="error"
                                showIcon
                            />
                        ) : blog ? (
                            <Space direction="vertical" size="large" style={{ width: '100%' }}>
                                <img
                                    src={blog.featuredImage || 'https://via.placeholder.com/800x400'}
                                    alt={blog.title}
                                    style={{
                                        width: '100%',
                                        height: '400px',
                                        objectFit: 'cover',
                                        borderRadius: '8px'
                                    }}
                                />
                                <Space direction="vertical" size="small">
                                    <Tag color="green">{blog.category || 'Blog'}</Tag>
                                    <Title level={1}>{blog.title}</Title>
                                    <Space>
                                        <CalendarOutlined />
                                        <Text type="secondary">
                                            {new Date(blog.date).toLocaleDateString()}
                                        </Text>
                                    </Space>
                                </Space>
                                <Paragraph style={{ fontSize: '16px', lineHeight: '1.8' }}>
                                    {blog.content}
                                </Paragraph>
                            </Space>
                        ) : (
                            <Alert
                                message="Blog not found"
                                type="error"
                                showIcon
                            />
                        )}
                    </Col>
                </Row>
            </Content>
            <Footer />
        </Layout>
    );
};

export default BlogDetail; 