import React from 'react';
import { Layout, Row, Col, Input, Button, Divider, Space, Typography } from 'antd';
import { FacebookOutlined, TwitterOutlined, InstagramOutlined, LinkedinOutlined, MailOutlined, TikTokOutlined } from '@ant-design/icons';

const { Footer } = Layout;
const { Text, Title } = Typography;

const AppFooter = () => {
  return (
    <Footer style={{ 
      background: '#f7f7f7',
      padding: '60px 0 30px',
      boxShadow: '0 -2px 10px rgba(0,0,0,0.05)'
    }}>
      <div style={{ 
        maxWidth: 1200, 
        margin: '0 auto', 
        padding: '0 20px'
      }}>
        <Row gutter={[48, 48]} justify="space-between">
          <Col xs={24} sm={12} md={8}>
            <img 
              src={require('../assets/logo.png')}
              alt="Tiko Logo" 
              style={{ 
                maxWidth: '180px',
                height: '180px',
                marginBottom: '25px',
                transition: 'transform 0.3s ease',
                ':hover': {
                  transform: 'scale(1.05)'
                }
              }} 
            />
          </Col>
          
          <Col xs={24} sm={12} md={8}>
            <Title level={4} style={{ 
              color: '#2c1810', 
              fontFamily: 'Balerno', 
              fontWeight: 'bold',
              marginBottom: '20px',
              fontSize: '1.5rem'
            }}>Contact</Title>
            <Text style={{ 
              color: '#5c4030', 
              display: 'block', 
              fontFamily: 'Balerno', 
              fontWeight: 'bold',
              fontSize: '1.1rem',
              marginBottom: '10px'
            }}>Phone: +254 795 825 320</Text>
            <Text style={{ 
              color: '#5c4030', 
              display: 'block', 
              fontFamily: 'Balerno', 
              fontWeight: 'bold',
              fontSize: '1.1rem'
            }}>Email: eventsbyzamari@gmail.com</Text>
          </Col>

          <Col xs={24} sm={12} md={8}>
            <Title level={4} style={{ 
              color: '#2c1810', 
              fontFamily: 'Balerno', 
              fontWeight: 'bold',
              marginBottom: '20px',
              fontSize: '1.5rem'
            }}>Office</Title>
            <Text style={{ 
              color: '#5c4030', 
              display: 'block', 
              fontFamily: 'Balerno', 
              fontWeight: 'bold',
              fontSize: '1.1rem',
              lineHeight: '1.8'
            }}>
              Kikuyu, Kenya
              <br />
              Nairobi, Kenya
              <br />
              P.O. Box 35410-00100
            </Text>
          </Col>
        </Row>

        <Divider style={{ 
          borderColor: '#e8d5c4', 
          margin: '32px 0',
          opacity: 0.8 
        }} />

        <Row justify="space-between" align="middle">
          <Col>
            <Text style={{ 
              color: '#5c4030', 
              fontFamily: 'Balerno', 
              fontWeight: 'bold',
              fontSize: '1rem'
            }}>
              © {new Date().getFullYear()} Zamari. All rights reserved.
            </Text>
          </Col>
          <Col>
            <Space size="large">
              <a 
                href="https://www.instagram.com/zamari_events" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ 
                  color: '#5c4030', 
                  fontSize: '24px',
                  transition: 'color 0.3s ease',
                  ':hover': {
                    color: '#2c1810'
                  }
                }}
              >
                <InstagramOutlined />
              </a>
              <a 
                href="https://www.tiktok.com/@zamari_events" 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ 
                  color: '#5c4030', 
                  fontSize: '24px',
                  transition: 'color 0.3s ease',
                  ':hover': {
                    color: '#2c1810'
                  }
                }}
              >
                <TikTokOutlined />
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </Footer>
  );
};

export default AppFooter;
